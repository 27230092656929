<template>
  <v-app>
    <v-main>
      <v-container grid-list-xs>
        <span class="text-h6">
          {{ $t("confHistory") }}
          <v-btn color="warning" @click="resetHistory">
            {{ $t("resetConfHistory") }}
          </v-btn>
        </span>
        <v-row>
          <v-col md="6">
            <v-card>
              <v-card-title primary-title>
                {{ $t("gsl") }}
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined small to="/gsl">{{
                  $t("returnGsl")
                }}</v-btn>
              </v-card-title>
              <v-card-text v-if="gslHistory.length > 0">
                <v-list disabled>
                  <v-list-item-group>
                    <v-list-item
                      two-line
                      v-for="(item, index) in gslHistory"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="$root.$i18n.locale === 'zh'">
                            {{ item.name_zh }}
                          </span>
                          {{ item.name_en }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t("time") }}
                          {{ item.gslTimer.min | doubleDigits }}:{{
                            item.gslTimer.sec | doubleDigits
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card>
              <v-container>
                <v-card-title primary-title>
                  {{ $t("motionHistory") }}
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" outlined small to="/motion">{{
                    $t("returnMotion")
                  }}</v-btn>
                </v-card-title>
                <v-card-text v-if="motionHistory.length > 0">
                  <div v-for="(item, index) in motionHistory" :key="index">
                    <v-list-item-title class="black--text">
                      <span v-if="$root.$i18n.locale === 'zh'">
                        {{ item.country && item.country.name_zh }}
                      </span>
                      {{
                        item.country && item.country.name_en
                      }}</v-list-item-title
                    >
                    <div class="black--text">
                      <span v-if="item.type === 'mod'">
                        {{ $t("mod") }}
                      </span>
                      <span v-if="item.type === 'unmod'">
                        {{ $t("unmod") }}
                      </span>
                      <span v-if="item.type === 'custom'">
                        {{ item.topic }}
                      </span>
                    </div>
                    <div v-if="item.topic">
                      <span> {{ $t("topic") }}:</span>
                      {{ item.topic }}
                    </div>
                    <div v-if="item.durationMin">
                      <span> {{ $t("totalTime") }} :</span>
                      {{ item.durationMin }}:{{
                        item.durationSec | doubleDigits
                      }}
                      {{ $t("min") }}
                    </div>
                    <div v-if="item.indDurationMin">
                      <span> {{ $t("everyDelegate") }}:</span>
                      {{ item.indDurationMin }}:{{
                        item.indDurationSec | doubleDigits
                      }}
                      {{ $t("min") }}
                    </div>
                    <p
                      v-if="item.status === 'pass'"
                      class="success--text"
                      block
                    >
                      {{ $t("pass") }}
                    </p>
                    <p v-else class="error--text" block>
                      {{ $t("fail") }}
                    </p>
                  </div>
                </v-card-text>
                <v-card-text v-else>
                  {{ $t("noMotionHistory") }}
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: this.$t("motionHistory"),
    };
  },
  filters: {
    doubleDigits: function (val) {
      const formattedNumber = ("0" + val).slice(-2);
      return formattedNumber;
    },
  },
  data() {
    return {
      dialog: false,
      tab: null,
      customTopics: [],
      custom: {},
      valid: true,
      validUnmod: true,
      validCustom: true,
      secondRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      secondCustomRules: [
        (v) => !v || (/^\d+$/.test(v) && v <= 60) || this.$t("input60"),
      ],
      minRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      minCustomRules: [
        (v) => !v || (/^\d+$/.test(v) && v <= 60) || this.$t("input60"),
      ],
      requiredRule: [(v) => !!v || this.$t("selectCountry")],
    };
  },
  computed: {
    countriesPresent() {
      const list = this.$store.getters.inSessionCountriesPresent;
      return list;
    },
    motionHistory() {
      return this.$store.state.currentSession.motionHistory;
    },
    gslHistory() {
      return this.$store.state.currentSession.speakingListHistory;
    },
  },
  mounted() {},
  methods: {
    resetHistory() {
      var result = confirm(this.$t("resetConfirmHistory"));
      if (result) {
        this.$store.commit("resetHistory");
        this.$store.dispatch("updateSession");
      }
    },
    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    countryFilter(item, queryText) {
      const textOne = item.name_zh;
      const textTwo = item.name_en.toLowerCase();
      const textThree = item.code.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1
      );
    },
  },
};
</script>


<i18n>
{
  "en": {
    "confHistory": "Conference History",
    "gsl": "General Speaking List",
    "returnGsl": "Return to General Speaking List",
    "resetConfirmHistory":"Are you sure to clear conference history?",
    "time": "Time: ",
    "motionHistory": "Motion History",
    "returnMotion": "Return to Motion",
    "motion": "Motion",
    "next": "Next",
    "topic": "Topic",
    "totalTime": "Total Time",
    "min": "Minute",
    "sec": "Second",
    "confirm": "Confirm",
    "continue": "Continue",
    "pass": "Pass",
    "mod": "Mod",
    "unmod": "UnMod",
    "custom": "custom",
    "everyDelegate": "Each Delegate",
    "fail": "Fail",
    "noMotion": "No Motion, Please Add New Ones",
    "clearAll": "Clear All",
    "newMotion": "New Motion",
    "submittingCountry": "Submitting Country",
    "input60": "Please Input Integer within 60 ",
    "resetConfHistory": "Reset Conference History",
    "customHint":"Select or Input Motions",
    "createHint":"No such motions，tap Enter to create one",
    "selectCountry":"Please Select Submitting Country",
    "pause": "Pause",
    "noMotionHistory":"No Motion History"
  },
  "zh": {
    "confHistory": "会议历史",
    "resetConfHistory": "重置会议记录",
    "returnGsl": "返回主发言名单",
    "resetConfirmHistory":"确定清除所有会议记录么？",
    "gsl": "主发言名单",
    "time": "时间：",
    "motionHistory": "历史动议",
    "returnMotion": "返回动议",
    "motion": "动议",
    "next": "下一位",
    "topic": "主题",
    "totalTime": "总时长",
    "min": "分钟",
    "sec": "秒",
    "confirm": "确定",
    "everyDelegate": "每位代表",
    "pass": "通过",
    "mod": "有组织核心磋商",
    "unmod": "无组织核心磋商",
    "custom": "自定义",
    "noMotion": "尚无动议",
    "fail": "未通过",
    "continue": "继续",
    "clearAll": "全部取消",
    "newMotion": "新增动议",
    "submittingCountry": "动议国",
    "input60": "输入60以内整数",
    "customHint":"输入或选择已有动议主题",
    "createHint":"没有相关动议，敲回车键新建一个",
    "selectCountry":"请选择国家",
    "pause": "暂停",
    "noMotionHistory":"尚无动议历史",
    "deleteConfirm":"确认删除所有动议么？"
  }
}
</i18n>